import './App.css';
import { Slider } from '@material-ui/core';
import { useState, useMemo } from 'react'

function App() {
  const [degree, setDegree] = useState(3);
  const [t, setT] = useState(0.5);

  const points = useMemo(() => {
    const nPoints = degree + 1;
    const nRandomNums = (n) => {
      const nums = [];
      for (let i = 0; i < n; i++) {
        nums.push(Math.random());
      }
      return nums;
    }

    const xs = nRandomNums(nPoints);
    const ys = nRandomNums(nPoints);
    const points = [];
    for (let i = 0; i < nPoints; i++) {
      points.push([xs[i], ys[i]]);
    }
    return points;
  }, [degree]);

  const decasteljau = (points, t) => {
    const linePoints = [[...points]];
    while (linePoints[linePoints.length - 1].length > 1) {
      const currLinePoints = linePoints[linePoints.length - 1];
      const nextLinePoints = [];
      for (let i = 0; i < currLinePoints.length - 1; i++) {
        const [x1, y1] = currLinePoints[i];
        const [x2, y2] = currLinePoints[i + 1];
        const x = x1 * (1 - t) + x2 * t;
        const y = y1 * (1 - t) + y2 * t;
        nextLinePoints.push([x, y]);
      }
      linePoints.push(nextLinePoints);
    }
    return linePoints;
  }

  const linesPerLevel = useMemo(() => {
    return decasteljau(points, t);
  }, [points, t]);

  const splineStr = useMemo(() => {
    let splineStr = "";
    const steps = 100;
    for (let i = 0; i < steps + 1; i++) {
      const t_ = 1 / steps * i;
      const ps = decasteljau(points, t_);
      const [x, y] = ps.pop()[0];
      splineStr += (x * 100) + "," + (y * 100) + " ";
    }
    return splineStr;
  }, [points]);

  return (
    <div className="column full-width">
      <div className="row full-width">
        <div className="column padding" style={{ flex: "1 1 30%", maxWidth: "200px" }}>
          <div>Degree = {degree}</div>
          <Slider min={1} max={12} value={degree} onChange={(ev, val) => {
            setDegree(val);
          }} />
          <div>t = {t}</div>
          <Slider min={0} max={1} step={0.01} value={t} onChange={(ev, val) => {
            setT(val);
          }} />
        </div>
        <div style={{ flex: "1 1 auto" }}>
          <svg viewBox="0 0 100 100" style={{ width: "100%", height: "100vh" }}>
            <polyline points={splineStr} fill="transparent" stroke="blue" strokeDasharray="0" strokeWidth={0.3} />
            {linesPerLevel.map((points, i) => {
              const color = (points.length === 1) ? "red" : "gray"
              const radius = (points.length === 1) ? 1 : 0.5;
              const op = (points.length === 1) ? 1 : 0.5;
              return points.map((p) => {
                const [x, y] = p;
                return <circle cx={x * 100} cy={y * 100} r={radius} fill={color} fillOpacity={op} />
              })
            })}
            {linesPerLevel.map((points) => {
              let ps = ""
              points.forEach(([x, y]) => {
                ps += (x * 100) + "," + (y * 100) + " ";
              });
              return <polyline points={ps} fill="transparent" stroke="black" strokeWidth={0.3} strokeOpacity={0.3}/>
            })}
          </svg>
        </div>
      </div>
    </div>
  );
}

export default App;
